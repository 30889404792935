/* .body-search-wrapper {
  height: 100vh;
}

.body-search__gradient {
  height: 43.5vh;
  background-image: url("https://i.imgur.com/qRcMaKh.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-bottom: 7px solid var(--secondary-color);
}

.body-search__media {
  height: 100vh;
  background-color: var(--primary-color);
}

.body-search__search {
  display: flex;
  justify-content: center;
}

.body-search__search-field {
  width: 40vw;
  height: 65px;
  font-size: 24px;
  font-weight: 600;
  text-transform: uppercase;
  border: none;
  border-radius: 3px;
  padding: 0px 20px;
  margin-top: -36px;
}

.body-search__search-field:focus,
.body-search__search-field:hover {
  outline: none;
}

.body-search__search-results {
  display: flex;
  flex-direction: column;
}

@media (min-width: 1280px) {
  .body-search__query-results {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fill, 245px);
    justify-content: space-between;
    grid-gap: 20px;
    background-color: var(--primary-color);
    padding: 120px 60px;
    overflow: hidden;
    scroll-behavior: smooth;
  }

  .body-search__query-result {
    width: 245px;
    height: 390px;
    background-image: url("https://i.imgur.com/qRcMaKh.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    cursor: pointer;
    transition: 250ms all;
  }

  .body-search__query-result:hover {
    transform: scale(1.2);
    transition: border-color 1s ease-in-out;
    opacity: 1;
    border: 4px solid #ffaa00;
  }

  .body-search_query-result:hover:first-child,
  .body-search_query-result:hover:nth-child(5n + 1) {
    transform: scale(1.2);
    transform-origin: left center;
    transition: border-color 1s ease-in-out;
    opacity: 1;
    border: 4px solid var(--secondary-color);
  }

  .body-search_query-result:hover:last-child,
  .body-search_query-result:hover:nth-child(5n) {
    transform: scale(1.2);
    transform-origin: right center;
    transition: border-color 1s ease-in-out;
    opacity: 1;
    border: 4px solid var(--secondary-color);
  }

  .body-search__query-not-found {
    width: 245px;
    height: 390px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    color: white;
    background-image: url("https://i.imgur.com/qRcMaKh.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    transition: 250ms all;
  }
}

@media (min-width: 1920px) {
  .body-search__query-results {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fill, 328px);
    justify-content: space-between;
    grid-gap: 20px;
    background-color: var(--primary-color);
    padding: 120px 60px;
  }

  .body-search__query-result {
    width: 328px;
    height: 492px;
    display: flex;
    font-size: 12px;
    text-align: center;
    color: white;
    background-image: url("https://i.imgur.com/qRcMaKh.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .body-search__query-result:hover {
    transform: scale(1.2);
    transition: border-color 1s ease-in-out;
    opacity: 1;
    border: 4px solid #ffaa00;
  }

  .body-search__query-result:hover:first-child,
  .body-search__query-result:hover:nth-child(7n + 1) {
    transform: scale(1.2);
    transform-origin: left center;
    transition: border-color 1s ease-in-out;
    opacity: 1;
    border: 4px solid var(--secondary-color);
  }
  .body-search__query-result:hover:last-child,
  .body-search__query-result:hover:nth-child(7n) {
    transform: scale(1.2);
    transform-origin: right center;
    transition: border-color 1s ease-in-out;
    opacity: 1;
    border: 4px solid var(--secondary-color);
  }

  .body-search__query-not-found {
    width: 328px;
    height: 492px;
    display: flex;
    justify-content: center;
    font-size: 20px;
    color: white;
    background-image: url("https://i.imgur.com/qRcMaKh.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    transition: 250ms all;
  }
} */

.ss-body {
  height: 100vh;
}

.ss-body__gradient {
  height: 43.5vh;
  background-image: url("https://i.imgur.com/qRcMaKh.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-bottom: 7px solid var(--secondary-color);
}

.ss-body__media {
  height: 100vh;
  background-color: var(--primary-color);
}

.ss-body__search {
  display: flex;
  justify-content: center;
}

.ss-body__search-field {
  width: 40vw;
  height: 65px;
  font-size: 24px;
  font-weight: 600;
  text-transform: uppercase;
  border: none;
  border-radius: 3px;
  padding: 0px 20px;
  margin-top: -36px;
  /* margin-bottom: 60px; */
}

.ss-body__search-field:focus,
.ss-body__search-field:hover {
  outline: none;
}

.ss-body__search-results {
  display: flex;
  flex-direction: column;
}

@media (min-width: 1280px) {
  .ss-body__query-results {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fill, 245px);
    justify-content: space-between;
    grid-gap: 20px;
    background-color: var(--primary-color);
    padding: 120px 60px;
    overflow: hidden;
    scroll-behavior: smooth;
  }

  .ss-body__query-result {
    width: 245px;
    height: 390px;
    background-image: url("https://i.imgur.com/qRcMaKh.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    cursor: pointer;
    transition: 250ms all;
  }

  .ss-body__query-result:hover {
    transform: scale(1.2);
    transition: border-color 1s ease-in-out;
    opacity: 1;
    border: 4px solid #ffaa00;
  }

  .ss-body__query-result:hover:first-child,
  .ss-body__query-result:hover:nth-child(5n + 1) {
    transform: scale(1.2);
    transform-origin: left center;
    transition: border-color 1s ease-in-out;
    opacity: 1;
    border: 4px solid var(--secondary-color);
  }

  .ss-body__query-result:hover:last-child,
  .ss-body__query-result:hover:nth-child(5n) {
    transform: scale(1.2);
    transform-origin: right center;
    transition: border-color 1s ease-in-out;
    opacity: 1;
    border: 4px solid var(--secondary-color);
  }

  .ss-body__query-result--unactive {
    width: 245px;
    height: 390px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    color: white;
    background-image: url("https://i.imgur.com/qRcMaKh.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    transition: 250ms all;
  }
}

@media (min-width: 1920px) {
  .ss-body__query-results {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fill, 328px);
    justify-content: space-between;
    grid-gap: 20px;
    background-color: var(--primary-color);
    padding: 120px 60px;
  }

  .ss-body__query-result {
    width: 328px;
    height: 492px;
    display: flex;
    font-size: 12px;
    text-align: center;
    color: white;
    background-image: url("https://i.imgur.com/qRcMaKh.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .ss-body__query-result:hover {
    transform: scale(1.2);
    transition: border-color 1s ease-in-out;
    opacity: 1;
    border: 4px solid #ffaa00;
  }

  .ss-body__query-result:hover:first-child,
  .ss-body__query-result:hover:nth-child(7n + 1) {
    transform: scale(1.2);
    transform-origin: left center;
    transition: border-color 1s ease-in-out;
    opacity: 1;
    border: 4px solid var(--secondary-color);
  }
  .ss-body__query-result:hover:last-child,
  .ss-body__query-result:hover:nth-child(7n) {
    transform: scale(1.2);
    transform-origin: right center;
    transition: border-color 1s ease-in-out;
    opacity: 1;
    border: 4px solid var(--secondary-color);
  }

  .ss-body__query-result--unactive {
    width: 328px;
    height: 492px;
    display: flex;
    justify-content: center;
    font-size: 20px;
    color: white;
    background-image: url("https://i.imgur.com/qRcMaKh.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    transition: 250ms all;
  }
}
