@media all and (min-width: 1440px) and (max-width: 2559px) {
  .reviews-wrapper {
    background-color: var(--primary-color);
    padding: 0px 60px;
    padding-bottom: 60px;
  }

  .reviews__title {
    font-size: 24px;
    color: white;
    padding-bottom: 16px;
    margin: 0;
  }

  .review {
    display: flex;
    background-color: var(--primary-color);
    border-bottom: 1px solid var(--secondary-color);
    padding: 32px 0px;
  }

  .review__user-details {
    width: 300px;
    display: flex;
    flex-direction: column;
    /* padding-right: 325px; */
    /* margin-right: auto; */
  }

  .review__rating {
    font-size: 24px;
    font-weight: 500;
    color: var(--secondary-color);
    margin-bottom: 16px;
  }

  .review__total-rating {
    font-size: 16px;
    font-weight: 400;
    color: var(--tertiary-color);
  }

  .review__author {
    font-size: 14px;
    font-weight: 400;
    color: var(--gray-200-color);
    margin-bottom: 16px;
  }

  .review__date {
    font-size: 14px;
    font-weight: 400;
    color: var(--gray-300-color);
    margin-bottom: 16px;
  }

  .review__desc {
    width: 70vw;
    /* margin-left: 120px; */
  }

  .review__title {
    font-weight: 16px;
    font-weight: 500;
    color: var(--tertiary-color);
  }

  .review__user-desc {
    font-size: 16px;
    color: var(--gray-300-color);
    text-align: justify;
    text-justify: inter-word;
    padding: 16px 0px;
  }

  .review__helpful {
    font-size: 14px;
    color: var(--gray-200-color);
    margin-bottom: 16px;
  }

  .review__helpful-text,
  .review__helpful-yes,
  .review__helpful-no {
    margin-right: 16px;
  }

  .review__helpful-yes,
  .review__helpful-no,
  .review__helpful-report {
    cursor: pointer;
  }
}

@media all and (min-width: 2560px) {
  .reviews-wrapper {
    background-color: var(--primary-color);
    padding: 0px 60px;
    padding-bottom: 60px;
  }

  .reviews__title {
    font-size: 32px;
    color: white;
    padding-bottom: 16px;
    margin: 0;
  }

  .review {
    display: flex;
    background-color: var(--primary-color);
    border-bottom: 1px solid var(--secondary-color);
    padding: 32px 0px;
  }

  .review__user-details {
    width: 650px;
    display: flex;
    flex-direction: column;
    /* padding-right: 325px; */
    /* margin-right: auto; */
  }

  .review__rating {
    font-size: 24px;
    font-weight: 500;
    color: var(--secondary-color);
    margin-bottom: 16px;
  }

  .review__total-rating {
    font-size: 16px;
    font-weight: 400;
    color: var(--tertiary-color);
  }

  .review__author {
    font-size: 14px;
    font-weight: 400;
    color: var(--gray-200-color);
    margin-bottom: 16px;
  }

  .review__date {
    font-size: 14px;
    font-weight: 400;
    color: var(--gray-300-color);
    margin-bottom: 16px;
  }

  .review__desc {
    width: 70vw;
    /* margin-left: 120px; */
  }

  .review__title {
    font-weight: 16px;
    font-weight: 500;
    color: var(--tertiary-color);
  }

  .review__user-desc {
    font-size: 16px;
    color: var(--gray-300-color);
    text-align: justify;
    text-justify: inter-word;
    padding: 16px 0px;
  }

  .review__helpful {
    font-size: 14px;
    color: var(--gray-200-color);
    margin-bottom: 16px;
  }

  .review__helpful-text,
  .review__helpful-yes,
  .review__helpful-no {
    margin-right: 16px;
  }

  .review__helpful-yes,
  .review__helpful-no,
  .review__helpful-report {
    cursor: pointer;
  }
}
