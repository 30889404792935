@media all and (min-width: 1440px) and (max-width: 2559px) {
  .medium-row-wrapper {
    /* height: 508px; */
    /* height: 496px; */
    height: 475px;
    background: var(--primary-color);
    padding: 0px 60px 0px 60px;
  }

  .medium-row-carousel {
    /* height: 506px; */
    /* height: 425px; */
    height: 494px;
  }

  .medium-row__title {
    font-size: 16px;
    text-transform: uppercase;
    color: var(--tertiary-color);
    margin: 0;
  }

  .swiper-horizontal > .swiper-pagination-bullets,
  .swiper-pagination-bullets.swiper-pagination-horizontal,
  .swiper-pagination-custom,
  .swiper-pagination-fraction {
    text-align: right;
  }

  .swiper {
    width: 100%;
    height: 100%;
  }

  .swiper-pagination {
    position: relative !important;
    padding: 42px 0px 0px 0px;
  }

  .swiper-pagination-bullet {
    background-color: var(--gray-200-color);
  }

  .swiper-pagination-bullet.swiper-pagination-bullet-active {
    border: none;
    background-color: var(--secondary-color);
  }

  .swiper-pagination.swiper-pagination-clickable.swiper-pagination-bullets.swiper-pagination-horizontal {
    width: 100% !important;
  }

  .medium-row__media-cards {
    height: 375px;
    /* width: 240px !important;
        height: 136px !important; */
    /* width: 169.8px !important; */

    /* width: 170px !important; */

    /* width: 118.2px !important; */

    /* width: 240px;
        height: 385px; */

    /* width: 240px; */
    /* height: 385px; */

    /* width: 173px !important;
        height: 275px !important; */
    text-align: center;
    font-size: 18px;
    border-radius: 8px;
    margin-bottom: 45px;
    background-color: red;
    /* margin: 10px 0px 50px 0px; */
    /* background: green; */
    cursor: pointer;

    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
  }

  .medium-row__media-cards.swiper-slide {
    transition: 250ms all;
  }

  .medium-row__media-cards.swiper-slide:hover {
    transform: scale(1.32);
    /* scale width 1.075, scale height  */
    transform-origin: center center;
    border: 4px solid var(--secondary-color);
    transition: border-color 1s ease-in-out;
    z-index: 1;
    /* width: 258px !important;
          height: 284px !important; */
    /* width: 258px !important;
          height: 284px !important; */
  }

  .medium-row__media-cards.swiper-slide:first-child:hover,
  .medium-row__media-cards.swiper-slide:hover:nth-child(5n + 1) {
    transform-origin: left center;
    border: 4px solid var(--secondary-color);
    transition: border-color 1s ease-in-out;
    /* border: 1px solid #000;
          background-color: red; */
    z-index: 1;
  }

  .medium-row__media-cards.swiper-slide:last-child:hover,
  .medium-row__media-cards.swiper-slide:hover:nth-child(5n) {
    transform-origin: right center;
    border: 4px solid var(--secondary-color);
    transition: border-color 1s ease-in-out;
    /* border: 1px solid #000;
          background-color: red; */
    z-index: 1;
  }
}

@media all and (min-width: 2560px) {
  .medium-row-wrapper {
    /* height: 508px; */
    /* height: 496px; */
    height: 650px;

    background: var(--primary-color);
    padding: 0px 60px 0px 60px;
  }

  .medium-row-carousel {
    /* height: 506px; */
    /* height: 425px; */
    height: 652px;
  }

  .medium-row__title {
    font-size: 32px;
    text-transform: uppercase;
    color: var(--tertiary-color);
    margin: 0;
  }

  .swiper-horizontal > .swiper-pagination-bullets,
  .swiper-pagination-bullets.swiper-pagination-horizontal,
  .swiper-pagination-custom,
  .swiper-pagination-fraction {
    text-align: right;
  }

  .swiper {
    width: 100%;
    height: 100%;
  }

  .swiper-pagination {
    position: relative !important;
    padding: 102px 0px 0px 0px;
  }

  .swiper-pagination-bullet {
    background-color: var(--gray-200-color);
  }

  .swiper-pagination-bullet.swiper-pagination-bullet-active {
    border: none;
    background-color: var(--secondary-color);
  }

  .swiper-pagination.swiper-pagination-clickable.swiper-pagination-bullets.swiper-pagination-horizontal {
    width: 100% !important;
  }

  .medium-row__media-cards {
    height: 500px;
    /* width: 240px !important;
        height: 136px !important; */
    /* width: 169.8px !important; */

    /* width: 170px !important; */

    /* width: 118.2px !important; */

    /* width: 240px;
        height: 385px; */

    /* width: 240px; */
    /* height: 385px; */

    /* width: 173px !important;
        height: 275px !important; */
    text-align: center;
    font-size: 18px;
    border-radius: 8px;
    margin-bottom: 45px;
    background-color: red;
    /* margin: 10px 0px 50px 0px; */
    /* background: green; */
    cursor: pointer;

    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
  }

  .medium-row__media-cards.swiper-slide {
    transition: 250ms all;
  }

  .medium-row__media-cards.swiper-slide:hover {
    border: 4px solid var(--secondary-color);
    transition: border-color 1s ease-in-out;
    z-index: 1;
  }
}
