@import url("https://fonts.googleapis.com/css2?family=Work+Sans:wght@400;500;600;700&display=swap");

:root {
  --primary-test-color: #037aeb;
  --accent-test-color: #02e7f5;
  --primary-color: #152240;
  --secondary-color: #fcc039;
  --tertiary-color: #ffffff;
  --background-color: #1a1d29;
  --gray-700-color: #101116;
  --gray-600-color: #1a1d29;
  --gray-500-color: #30323d;
  --gray-400-color: #5f6169;
  --gray-300-color: #74757d;
  --gray-200-color: #c8c9cb;
  --gray-100-color: #f9f9f9;
  --error-color: #fc7c7c;
  --success-color: #abfab3;
  --warning-color: #ffcc6c;
  --info-color: #b5affe;

  /* testing */
  --bg: #242526;
  --bg-accent: #484a4d;
  --text-color: #dadce1;
  --nav-size: 60px;
  --border: 1px solid #474a4d;
  --border-radius: 8px;
  --speed: 500ms;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: "Work Sans", sans-serif;
  overflow-x: hidden;
  background-color: var(--primary-color);
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

a {
  color: var(--text-color);
  text-decoration: none;
}
