@media all and (min-width: 1440px) and (max-width: 2559px) {
  .cast-wrapper {
    /* height: 360px; */
    /* height: 100vh; */

    background: var(--primary-color);
    /* padding: 0px 16px 0px 80px; */
    padding: 60px 60px 60px 60px;
  }

  .cast-row__title {
    font-size: 24px;
    color: var(--tertiary-color);
    margin: 0;
  }

  .swiper-button-prev,
  .swiper-button-next {
    color: var(--gray-100-color) !important;
    padding-bottom: 20px !important;
  }

  .swiper-horizontal > .swiper-pagination-bullets,
  .swiper-pagination-bullets.swiper-pagination-horizontal,
  .swiper-pagination-custom,
  .swiper-pagination-fraction {
    text-align: right;
  }

  .swiper-pagination {
    position: relative !important;
    right: 30px;
    padding: 16px 0px 0px 0px;
  }

  .swiper-pagination.swiper-pagination-clickable.swiper-pagination-bullets.swiper-pagination-horizontal {
  }

  .swiper-pagination-bullet {
    background-color: var(--gray-200-color);
  }
  .swiper-pagination-bullet.swiper-pagination-bullet-active {
    border: none;
    background-color: var(--secondary-color);
  }

  .swiper {
    width: 100%;
    height: 100%;
  }

  .swiper-slide {
    /* height: 300px; */
    transition: 250ms all;
  }

  .cast-row__media-cards {
    /* width: 168px !important; */
    /* width: 196px !important;
      height: 296px !important; */

    /* width: 186px !important; */
    /* width: 187.5px !important; */
    /* width: 187.6px !important; */
    height: 296px !important;
    display: flex;
    flex-direction: column;
    justify-content: flex-end !important;
    text-align: center;
    /* font-size: 18px; */
    background-color: #1a2644;

    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    /* margin-left: 50px; */
  }

  .cast-row__fade-bottom {
    width: 100%;
    /* height: 75px; */
    height: 105px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    background-image: linear-gradient(
      180deg,
      transparent,
      rgba(37, 37, 37, 0.7),
      /* var(--background-color) */ var(--primary-color)
    );
    padding-bottom: 16px;
  }

  .cast-row__actor-name {
    font-size: 14px;
    color: white;
    margin-bottom: 8px;
  }

  .cast-row__name {
    font-size: 14px;
    color: var(--gray-300-color);
  }
}

@media all and (min-width: 2560px) {
  .cast-wrapper {
    /* height: 360px; */
    /* height: 100vh; */

    background: var(--primary-color);
    /* padding: 0px 16px 0px 80px; */
    padding: 60px 60px 60px 60px;
  }

  .cast-row__title {
    font-size: 32px;
    color: var(--tertiary-color);
    margin: 0;
  }

  .swiper-horizontal > .swiper-pagination-bullets,
  .swiper-pagination-bullets.swiper-pagination-horizontal,
  .swiper-pagination-custom,
  .swiper-pagination-fraction {
    text-align: right;
  }

  .swiper-pagination {
    position: relative !important;
    right: 30px;
    padding: 16px 0px 0px 0px;
  }

  .swiper-pagination.swiper-pagination-clickable.swiper-pagination-bullets.swiper-pagination-horizontal {
  }

  .swiper-pagination-bullet {
    background-color: var(--gray-200-color);
  }
  .swiper-pagination-bullet.swiper-pagination-bullet-active {
    border: none;
    background-color: var(--secondary-color);
  }

  .swiper {
    width: 100%;
    height: 100%;
  }

  .swiper-slide {
    /* height: 300px; */
    transition: 250ms all;
  }

  .cast-row__media-cards {
    /* width: 168px !important; */
    /* width: 196px !important;
      height: 296px !important; */

    /* width: 186px !important; */
    /* width: 187.5px !important; */
    /* width: 187.6px !important; */
    height: 356px !important;
    display: flex;
    flex-direction: column;
    justify-content: flex-end !important;
    text-align: center;
    /* font-size: 18px; */
    background-color: #1a2644;

    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    /* margin-left: 50px; */
  }

  .cast-row__fade-bottom {
    width: 100%;
    /* height: 75px; */
    height: 105px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    background-image: linear-gradient(
      180deg,
      transparent,
      rgba(37, 37, 37, 0.7),
      /* var(--background-color) */ var(--primary-color)
    );
    padding-bottom: 16px;
  }

  .cast-row__actor-name {
    font-size: 16px;
    color: white;
    margin-bottom: 8px;
  }

  .cast-row__name {
    font-size: 16px;
    color: var(--gray-300-color);
  }
}
