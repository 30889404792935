.nav-item {
  width: calc(var(--nav-size) * 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 2px;
}

.icon-button {
  --button-size: calc(var(--nav-size) * 0.5);
  width: var(--button-size);
  height: var(--button-size);
  background-color: #484a4d;
  border-radius: 50%;
  padding: 5px;
  margin: 2px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: filter 300ms;
}

.icon-button:hover {
  /* filter: brightness(1.2); */
}

.icon-button svg {
  /* fill: var(--text-color);
  width: 20px;
  height: 20px; */
}

.nav-item__drop-down {
  width: 22px;
  height: 22px;
}
