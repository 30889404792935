.navbar-external {
  width: 100vw;
  height: 88px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  background-color: var(--primary-color);
  z-index: 50;
  padding: 0px 60px;
}

.navbar-external__logo {
  width: auto;
  height: 48px;
  cursor: pointer;
  margin-right: auto;
}

.navbar-external__sign-in {
  width: auto;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: bold;
  color: var(--background-color);
  border: none;
  border-radius: 4px;
  background-color: var(--tertiary-color);
  cursor: pointer;
  padding: 0px 50px;
}

.navbar-external__sign-in-icon {
  width: 16px;
  height: 16px;
  margin: 0px 8px 2px 0px;
}
