.navbar-wrapper--scroll {
  width: 100vw;
  height: 88px;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  background-image: url("https://i.imgur.com/qRcMaKh.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-bottom: 1px solid var(--grey-bb);
  z-index: 60;
  padding: 0 60px;
}

.navbar-testing-wrapper {
  height: 88px;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  background-color: transparent;
  border-bottom: 1px solid var(--grey-bb);
  z-index: 50;
  padding: 0px 60px 0px 60px;
}

.navbar-nav {
  max-width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-around;
  /* justify-content: flex-end; */
}
