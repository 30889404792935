.dropdown {
  position: absolute;
  top: 86px;
  width: 300px;
  transform: translate(-45%);
  background-color: var(--bg);
  border: var(--border);
  border-radius: var(--border-radius);
  padding: 1rem;
  overflow: hidden;
}

.menu-item {
  height: 50px;
  display: flex;
  align-items: center;
  border-radius: var(--border-radius);
  transition: background var(--speed);
  padding: 0.5rem;
}

.menu-item:hover {
  background-color: #525357;
}

.icon-button {
  margin-right: 8px;
}
