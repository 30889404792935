@media all and (min-width: 1440px) and (max-width: 2559px) {
  .watch-providers-wrapper {
    background-color: var(--primary-color);
    padding: 0 60px 60px 60px;
  }

  .watch-providers__filters {
    width: 100%;
    height: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* background-color: #1e2b48; */
    padding: 0px 0px 16px 0px;
  }

  .watch-providers__title {
    font-size: 24px;
    color: white;
    margin: 0;
  }

  .watch-providers__watch-now {
    width: 100%;
    height: auto;
    background-color: var(--primary-color);
    padding: 16px 0px;
  }

  .watch-providers__stream,
  .watch-providers__buy,
  .watch-providers__rent {
    height: 110px;
    display: flex;
    /* background-color: blue; */
    border-bottom: 0.5px solid var(--gray-400-color);
  }

  .watch-providers__title {
  }

  .watch-providers__watch-now-title {
    height: 110px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-weight: 500;
    text-transform: uppercase;
    writing-mode: tb-rl;
    -webkit-transform: rotate(-180deg);
    -moz-transform: rotate(-180deg);
    -o-transform: rotate(-180deg);
    -ms-transform: rotate(-180deg);
    transform: rotate(-180deg);
    background-color: red;
    padding: 0px 3px;
  }

  #stream {
    color: var(--gray-400-color);
    background-color: var(--gray-100-color);
  }

  #buy {
    color: var(--gray-100-color);
    background-color: var(--gray-300-color);
  }

  #rent {
    /* color: wheat; */
    color: var(--gray-500-color);
    background-color: var(--secondary-color);
  }

  .watch-providers__logo-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px 0px 0px 16px;
  }

  .watch-provider__logo {
    width: 50px;
    height: 50px;
    border-radius: 22%;
  }

  .watch-provider__name {
    font-size: 14px;
    color: var(--gray-200-color);
    padding-top: 8px;
  }
}

@media all and (min-width: 2560px) {
  .watch-providers-wrapper {
    background-color: var(--primary-color);
    padding: 0 60px 60px 60px;
  }

  .watch-providers__filters {
    width: 100%;
    height: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* background-color: #1e2b48; */
    padding: 0px 0px 16px 0px;
  }

  .watch-providers__title {
    font-size: 32px;
    color: white;
    margin: 0;
  }

  .watch-providers__watch-now {
    width: 100%;
    height: auto;
    background-color: var(--primary-color);
    padding: 16px 0px;
  }

  .watch-providers__stream,
  .watch-providers__buy,
  .watch-providers__rent {
    height: 110px;
    display: flex;
    /* background-color: blue; */
    border-bottom: 0.5px solid var(--gray-400-color);
  }

  .watch-providers__watch-now-title {
    height: 110px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-weight: 500;
    text-transform: uppercase;
    writing-mode: tb-rl;
    -webkit-transform: rotate(-180deg);
    -moz-transform: rotate(-180deg);
    -o-transform: rotate(-180deg);
    -ms-transform: rotate(-180deg);
    transform: rotate(-180deg);
    background-color: red;
    padding: 0px 3px;
  }

  #stream {
    color: var(--gray-400-color);
    background-color: var(--gray-100-color);
  }

  #buy {
    color: var(--gray-100-color);
    background-color: var(--gray-300-color);
  }

  #rent {
    /* color: wheat; */
    color: var(--gray-500-color);
    background-color: var(--secondary-color);
  }

  .watch-providers__logo-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px 0px 0px 16px;
  }

  .watch-provider__logo {
    width: 50px;
    height: 50px;
    border-radius: 22%;
  }

  .watch-provider__name {
    font-size: 14px;
    color: var(--gray-200-color);
    padding-top: 8px;
  }
}
