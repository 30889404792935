.navbar-wrapper--scroll {
  width: 100vw;
  height: 88px;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  background-image: url("https://i.imgur.com/qRcMaKh.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-bottom: 1px solid var(--grey-bb);
  z-index: 60;
  padding: 0 60px;
}

.navbar-wrapper {
  width: 100vw;
  height: 88px;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  background-color: transparent;
  border-bottom: 1px solid var(--grey-bb);
  z-index: 50;
  padding: 0px 60px 0px 60px;
}

.navbar__logo {
  height: 48px;
  cursor: pointer;
  padding-right: 24px;
}

.navbar__categories {
  margin-right: auto;
}

.navbar__categorie {
  font-size: 16px;
  color: var(--tertiary-color);
  cursor: pointer;
  padding: 0px 16px;
}

.navbar__functionality {
  display: flex;
  color: var(--tertiary-color);
}

.navbar__search,
.navbar__watchlist {
  display: flex;
  align-items: center;
  /* display: flex;
  flex-direction: row;
  align-items: center; */
  font-size: 16px;
  cursor: pointer;
  padding: 0px 16px;
}

.navbar__search-icon,
.navbar__watchlist-icon {
  margin-right: 8px;
}

.navbar__sign-in {
  width: auto;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: bold;
  color: var(--background-color);
  border: none;
  border-radius: 4px;
  background-color: var(--tertiary-color);
  cursor: pointer;
  padding: 0px 25px;
  margin: 0px 14px 0px 16px;
}

.navbar__sign-in-icon {
  width: 16px;
  height: 16px;
  margin: 0px 8px 2px 0px;
}

.navbar__user-profile {
  display: flex;
  align-items: center;
}

.navbar__user {
  width: 48px;
  height: 48px;
  margin: 0px 16px 0px 16px;
  cursor: pointer;
}

.navbar__user-arrow {
  width: 22px;
  height: 22px;
  color: white;
  cursor: pointer;
  margin-right: 14px;
}
