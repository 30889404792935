@media all and (min-width: 1440px) and (max-width: 2559px) {
  .similar-wrapper {
    width: 100%;
    background-color: var(--primary-color);
    /* margin: 0px 80px; */
    padding: 0px 60px 60px 60px;
  }

  .similar__title {
    font-size: 24px;
    color: white;
    margin: 0;
  }

  .similar__media-container {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
  }

  .similar__title,
  .reviews__title,
  .media-photos__title,
  .watch-provider__title {
    margin: 0;
    padding-bottom: 32px;
  }

  .similar__media {
    margin: 0;
  }

  .similar__media-poster {
    width: 249px;
    height: 400px;
    /* height: 366px; */
    cursor: pointer;
  }

  .similar__media:hover {
    display: flex;
    transform: scale(1.2);
    transform-origin: center center;
    transition: border-color 1s ease-in-out;
    border: 4px solid var(--secondary-color);
    margin-bottom: -4px;
    margin-right: -4px;
    margin-left: -4px;
  }

  .similar__media:hover:first-child,
  .similar__media:hover:nth-child(5n + 1) {
    transform: scale(1.2);
    transform-origin: left center;
    transition: border-color 1s ease-in-out;
    opacity: 1;
    border: 4px solid var(--secondary-color);
  }
  .similar__media:hover:last-child,
  .similar__media:hover:nth-child(5n) {
    transform: scale(1.2);
    transform-origin: right center;
    transition: border-color 1s ease-in-out;
    opacity: 1;
    border: 4px solid var(--secondary-color);
  }
}

@media all and (min-width: 2560px) {
  .similar-wrapper {
    width: 100%;
    background-color: var(--primary-color);
    /* margin: 0px 80px; */
    padding: 0px 60px 60px 60px;
  }

  .similar__title {
    font-size: 32px;
    color: white;
    margin: 0;
  }

  .similar__media-container {
    display: flex;
    flex-wrap: wrap;
    gap: 25px;
  }

  .similar__title,
  .reviews__title,
  .media-photos__title,
  .watch-provider__title {
    margin: 0;
    padding-bottom: 32px;
  }

  .similar__media {
    margin: 0;
  }

  .similar__media-poster {
    /* width: 249px; */
    width: 383px;
    /* width: 465px; */
    /* height: 616px; */
    /* height: 366px; */
    cursor: pointer;
  }

  .similar__media:hover {
    display: flex;
    transform: scale(1.2);
    transform-origin: center center;
    transition: border-color 1s ease-in-out;
    border: 4px solid var(--secondary-color);
    margin-bottom: -4px;
    margin-right: -4px;
    margin-left: -4px;
  }

  .similar__media:hover:first-child,
  .similar__media:hover:nth-child(5n + 1) {
    transform: scale(1.2);
    transform-origin: left center;
    transition: border-color 1s ease-in-out;
    opacity: 1;
    border: 4px solid var(--secondary-color);
  }
  .similar__media:hover:last-child,
  .similar__media:hover:nth-child(5n) {
    transform: scale(1.2);
    transform-origin: right center;
    transition: border-color 1s ease-in-out;
    opacity: 1;
    border: 4px solid var(--secondary-color);
  }
}
